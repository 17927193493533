import React, { Component } from "react"
import { Input, Select } from "../../components"
import { withRouter } from "react-router-dom"
import { create } from "../../helpers/movimentosApi"
import { mapErrors, showNotification } from "../../helpers/Constants"
import { resumoDeContratos } from "../../helpers/reportsApi"
import AdBlockDetect from "react-ad-block-detect"

class ResumoDeContratosForm extends Component {
  constructor() {
    super()
    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.toggleWaiting = this.toggleWaiting.bind(this)
    this.state = {
      errors: {},
      isLoading: true,
      isWaiting: false,
      fontesSelected: [],
      tiposDeDocumentoSelected: [],
      origensSelected: [],
      especiesSelected: [],
      tiposSelected: [],
    }
  }

  componentWillMount() {
    this.getInitialData()
  }
  getInitialData() {
    create((error, data) => {
      this.toggleLoading()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        let fontes = data.fontes.map(fontes => {
          return { value: fontes.id, label: fontes.nome }
        })
        let origens = data.origens.map(origem => {
          return { value: origem.id, label: origem.nome }
        })
        let especies = data.especies.map(especie => {
          return { value: especie.id, label: especie.nome }
        })
        let tiposDeMovimento = data.tipos_de_movimento.map(tipo => {
          return { value: tipo.id, label: tipo.nome }
        })
        let tiposDeDocumento = data.tipos_de_documento.map(tipo => {
          return { value: tipo.id, label: tipo.nome }
        })
        this.setState({
          fontes: fontes,
          especies: especies,
          origens: origens,
          tipos: tiposDeMovimento,
          tiposDeDocumento: tiposDeDocumento
        })
      }
    })
  }
  toggleLoading(callBack) {
    this.setState(
      {
        isLoading: !this.state.isLoading
      },
      () => {
        if (callBack) {
          callBack()
        }
      }
    )
  }
  toggleWaiting(callBack) {
    this.setState(
      {
        isWaiting: !this.state.isWaiting
      },
      () => {
        if (callBack) {
          callBack()
        }
      }
    )
  }
  handleChange(e) {
    let name = e.target.name
    let value = e.target.value
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: value,
      errors: errors
    })
  }
  onSubmit(e) {
    e.preventDefault()
    let params = {
      tipos: this.state.tiposSelected.map(item => {
        return item.value
      }),
      fontes: this.state.fontesSelected.map(item => {
        return item.value
      }),
      tipos_documentos: this.state.tiposDeDocumentoSelected.map(item => {
        return item.value
      }),
      origens: this.state.origensSelected.map(item => {
        return item.value
      }),
      especies: this.state.especiesSelected.map(item => {
        return item.value
      }),
      titulo: this.state.titulo,
      ano: this.state.ano,
      nome_origem: this.state.nome_origem,
      historico: this.state.historico,
    }
    this.toggleWaiting()
    resumoDeContratos(params, (error, data) => {
      this.toggleWaiting()
      if (error) {
        var message = ""
        switch (error.response.status) {
          case 400:
            message = "Não há dados a serem exibidos!"
            break
          case 422:
            message = "Ocorreram erros, verifiqueo formulário e tente novamente"
            break
          default:
            message = "Ocorreram erros, verifique sua conexão com a internet"
            break
        }
        showNotification(
          "top",
          "center",
          message,
          error.response.status === 400 ? "warning" : "danger"
        )
        if (error.response.status === 422) {
          const reader = new FileReader()
          reader.addEventListener("loadend", e => {
            const text = e.srcElement.result
            let json = JSON.parse(text)
            this.setState({
              errors: mapErrors({ response: { data: json } })
            })
          })
          reader.readAsText(error.response.data)
        }
      } else {
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("download", "ResumoDeContratos.xls")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    })
  }
  render() {
    return (
      <div className="card shadow">
        <div className="card-header border-0 bg-secondary">
          <div className="row text-center">
            <div className="col-lg-12">
              <h1>Resumo de contratos</h1>
            </div>
          </div>
        </div>
        <div className="card-body">
          {this.state.isLoading ? (
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="lds-ring">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
            </div>
          ) : (
              <form onSubmit={this.onSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <Input
                      label="Título do relatório"
                      name="titulo"
                      placeholder="Título do relatório"
                      type="text"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      defaultValue={this.state.titulo}
                      onChange={this.handleChange}
                      error={this.state.errors.titulo}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="offset-lg-2 col-lg-4">
                    <Select
                      label="Tipos"
                      name="tiposSelected"
                      isMulti={true}
                      options={this.state.tipos}
                      placeholder="Todos"
                      value={this.state.tiposSelected}
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      onChange={this.handleChange}
                      error={this.state.errors.tipos}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Select
                      label="Especies"
                      name="especiesSelected"
                      isMulti={true}
                      options={this.state.especies}
                      placeholder="Todos"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      value={this.state.especiesSelected}
                      onChange={this.handleChange}
                      error={this.state.errors.especies}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="offset-lg-2 col-lg-4">
                    <Select
                      label="Fontes"
                      name="fontesSelected"
                      isMulti={true}
                      options={this.state.fontes}
                      placeholder="Todos"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      value={this.state.fontesSelected}
                      onChange={this.handleChange}
                      error={this.state.errors.fontes}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Select
                      label="Tipos de documento"
                      name="tiposDeDocumentoSelected"
                      isMulti={true}
                      options={this.state.tiposDeDocumento}
                      placeholder="Todos"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      value={this.state.tiposDeDocumentoSelected}
                      onChange={this.handleChange}
                      error={this.state.errors.tipos_documentos}
                    />
                  </div>
                </div>
                <div className="card shadow">
                  <div className="card-body bg-secondary">
                    <div className="row">
                      <div className="col-lg-12">
                        <Select
                          label="Selecione origens específicas"
                          name="origensSelected"
                          isMulti={true}
                          options={this.state.origens}
                          placeholder="Todos"
                          disabled={this.state.isWaiting ? "disabled" : undefined}
                          value={this.state.origensSelected}
                          onChange={this.handleChange}
                          error={this.state.errors.origens}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 text-center">Ou</div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <Input
                          label="Digite o nome da origem"
                          name="nome_origem"
                          placeholder="Nome da origem"
                          type="text"
                          disabled={this.state.isWaiting ? "disabled" : undefined}
                          defaultValue={this.state.nome_origem}
                          onChange={this.handleChange}
                          error={this.state.errors.nome_origem}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <Input
                      label="Histórico"
                      name="historico"
                      placeholder="Histórico"
                      type="text"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      defaultValue={this.state.historico}
                      onChange={this.handleChange}
                      error={this.state.errors.historico}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="offset-lg-2 col-lg-4">
                    <Input
                      label="Ano"
                      name="ano"
                      placeholder="Ano"
                      type="text"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      defaultValue={this.state.ano}
                      onChange={this.handleChange}
                      error={this.state.errors.ano}
                    />
                  </div>
                </div>
                <AdBlockDetect>
                  <div className="row">
                    <div className="col-lg-12 text-center bg-warning text-white py-3 my-4">
                      <i
                        style={{ fontSize: 50 }}
                        className="fas fa-exclamation-triangle"
                      />
                      <br />
                      <br /> Para que o relatório seja emitido, é necessário que
                      você desabilite seu AdBlocker
                  </div>
                  </div>
                </AdBlockDetect>
                {this.state.isWaiting && (
                  <div>
                    <div className="row">
                      <div className="col-lg-12 text-center">
                        <div className="lds-ring">
                          <div />
                          <div />
                          <div />
                          <div />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 text-center">
                        <h3 className="text-center">
                          Aguarde por favor, essa tarefa pode levar alguns
                          minutos!
                      </h3>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-lg-2 offset-lg-5">
                    <button
                      type="submit"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      className="btn btn-primary btn-block btn-lg"
                    >
                      <span className="btn--inner-icon">
                        <i className="ni ni-send" />
                      </span>
                      <span className="btn--inner-text"> Enviar</span>
                    </button>
                  </div>
                </div>
              </form>
            )}
        </div>
      </div>
    )
  }
}

export default withRouter(ResumoDeContratosForm)
