import React, { Component } from "react"
import { Link } from "react-router-dom"
export default class CollapseMenu extends Component {
  constructor() {
    super()
    this.toggleCollapse = this.toggleCollapse.bind(this)
    this.state = {
      collapsed: true
    }
  }
  toggleCollapse() {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }
  render() {
    return (
      <li className="nav-item rounded-nav-link">
        <button
          style={{ fontWeight: 400 }}
          className="nav-link btn btn-link text-left"
          onClick={this.toggleCollapse}
        >
          <i style={{ marginRight: 0 }} className={`${this.props.item.icon}`} />
          {this.props.item.label}
          <i
            className={`text-right ${
              this.state.collapsed ? "fas fa-caret-down" : "fas fa-caret-up"
            }`}
          />
        </button>
        <div
          className={`collapse collapse-animated  ${
            this.state.collapsed ? "" : "show"
          }`}
        >
          <ul className="nav">
            {this.props.item.subItens.map((subItem, subItemKey) => {
              return (
                <li key={subItemKey} className="nav-item ">
                  <Link className="nav-link" to={subItem.link}>
                    <span className="sidebar-normal">{subItem.label}</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </li>
    )
  }
}
