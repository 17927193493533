import axios from "axios"
import { API, defaultHeaders } from "./Constants"
export function get(page, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = API + `transferencias${page ? `?page=${page}` : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
export function search(searchText, filters, onStart, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = API + `search/transferencias?text=${searchText}&fields=${filters}`
  onStart()
  return axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
export function edit(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = API + `transferencias/${id}/edit`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
export function post(params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = API + "transferencias"
  axios
    .post(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function put(id, params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = API + "transferencias/" + id
  axios
    .put(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function destroy(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = API + "transferencias/" + id
  axios
    .delete(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
export function create(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = API + "transferencias/create"
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
