import React, { Component } from "react"
import { Input, Select } from "../../components"
import { withRouter } from "react-router-dom"
import { create } from "../../helpers/movimentosApi"
import { mapErrors, showNotification } from "../../helpers/Constants"
import { pagamentosPorPeriodo } from "../../helpers/reportsApi"
import AdBlockDetect from "react-ad-block-detect"

class PagamentoPorPeriodoForm extends Component {
  constructor() {
    super()
    var date = new Date()
    var currentDate = [
      date.getFullYear(),
      (date.getMonth() + 1 + "").padStart(2, "0"),
      (date.getDate() + "").padStart(2, "0")
    ].join("-")
    var yesterday = [
      date.getFullYear(),
      (date.getMonth() + 1 + "").padStart(2, "0"),
      (date.getDate() - 1 + "").padStart(2, "0")
    ].join("-")
    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.toggleWaiting = this.toggleWaiting.bind(this)
    this.state = {
      errors: {},
      isLoading: true,
      isWaiting: false,
      data_final: currentDate,
      data_inicial: yesterday,
      fontesSelected: [],
      tiposDeDocumentoSelected: [],
      origensSelected: [],
      especiesSelected: [],
      tiposSelected: [],
      order: { value: "m.id", label: "Nº Do Movimento" },
      campo_data: { value: "emissao", label: "Data de emissão" },
      reportTypes: [
        { value: 1, label: "Pagamentos por período (pdf)" },
        { value: 2, label: "Pagamentos por período (xls)" },
        { value: 3, label: "Pagamentos por período xls com histórico" },
        { value: 4, label: "Pagamentos por período simplificado" },
      ],
      tipo_relatorio: 1,
      data_inicial_anterior: "",
      data_final_anterior: "",
    }
  }

  componentWillMount() {
    this.getInitialData()
  }
  getInitialData() {
    create((error, data) => {
      this.toggleLoading()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        let fontes = data.fontes.map(fontes => {
          return { value: fontes.id, label: fontes.nome }
        })
        let origens = data.origens.map(origem => {
          return { value: origem.id, label: origem.nome }
        })
        let especies = data.especies.map(especie => {
          return { value: especie.id, label: especie.nome }
        })
        let tiposDeMovimento = data.tipos_de_movimento.map(tipo => {
          return { value: tipo.id, label: tipo.nome }
        })
        let tiposDeDocumento = data.tipos_de_documento.map(tipo => {
          return { value: tipo.id, label: tipo.nome }
        })
        this.setState({
          fontes: fontes,
          especies: especies,
          origens: origens,
          tipos: tiposDeMovimento,
          tiposDeDocumento: tiposDeDocumento
        })
      }
    })
  }
  toggleLoading(callBack) {
    this.setState(
      {
        isLoading: !this.state.isLoading
      },
      () => {
        if (callBack) {
          callBack()
        }
      }
    )
  }
  toggleWaiting(callBack) {
    this.setState(
      {
        isWaiting: !this.state.isWaiting
      },
      () => {
        if (callBack) {
          callBack()
        }
      }
    )
  }
  handleChange(e) {
    let name = e.target.name
    let value = e.target.value
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: value,
      errors: errors
    })
  }
  onSubmit(e) {
    e.preventDefault()
    let params = {
      tipos: this.state.tiposSelected.map(item => {
        return item.value
      }),
      fontes: this.state.fontesSelected.map(item => {
        return item.value
      }),
      tipos_documentos: this.state.tiposDeDocumentoSelected.map(item => {
        return item.value
      }),
      origens: this.state.origensSelected.map(item => {
        return item.value
      }),
      especies: this.state.especiesSelected.map(item => {
        return item.value
      }),
      data_inicial: this.state.data_inicial,
      titulo: this.state.titulo,
      numero_do_contrato: this.state.numero_do_contrato,
      data_final: this.state.data_final,
      order: this.state.order.value,
      nome_origem: this.state.nome_origem,
      historico: this.state.historico,
      campo_data: this.state.campo_data
        ? this.state.campo_data.value
        : undefined,
        tipo_relatorio: this.state.tipo_relatorio.value
    }
    if(this.state.tipo_relatorio.value == 4){
      params.data_inicial_anterior = this.state.data_inicial_anterior;
      params.data_final_anterior = this.state.data_final_anterior;
      params.adicionar_valores_anteriores = this.state.adicionar_valores_anteriores;
    }
    this.toggleWaiting()
    pagamentosPorPeriodo(params, (error, data) => {
      this.toggleWaiting()
      if (error) {
        var message = ""
        switch (error.response.status) {
          case 400:
            message = "Não há dados a serem exibidos!"
            break
          case 422:
            message = "Ocorreram erros, verifiqueo formulário e tente novamente"
            break
          default:
            message = "Ocorreram erros, verifique sua conexão com a internet"
            break
        }
        showNotification(
          "top",
          "center",
          message,
          error.response.status === 400 ? "warning" : "danger"
        )
        if (error.response.status === 422) {
          const reader = new FileReader()
          reader.addEventListener("loadend", e => {
            const text = e.srcElement.result
            let json = JSON.parse(text)
            this.setState({
              errors: mapErrors({ response: { data: json } })
            })
          })
          reader.readAsText(error.response.data)
        }
      } else {
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        if(this.state.xls){
          tempLink.setAttribute("download", "PagamentosPorPeriodo.xls")
        }
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    })
  }
  render() {
    return (
      <div className="card shadow">
        <div className="card-header border-0 bg-secondary">
          <div className="row text-center">
            <div className="col-lg-12">
              <h1>Relatório de pagamentos por período</h1>
            </div>
          </div>
        </div>
        <div className="card-body">
          {this.state.isLoading ? (
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="lds-ring">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
            </div>
          ) : (
              <form onSubmit={this.onSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <Input
                      label="Título do relatório"
                      name="titulo"
                      placeholder="Título do relatório"
                      type="text"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      defaultValue={this.state.titulo}
                      onChange={this.handleChange}
                      error={this.state.errors.titulo}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="offset-lg-2 col-lg-4">
                    <Select
                      label="Tipos"
                      name="tiposSelected"
                      isMulti={true}
                      options={this.state.tipos}
                      placeholder="Todos"
                      value={this.state.tiposSelected}
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      onChange={this.handleChange}
                      error={this.state.errors.tipos}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Select
                      label="Especies"
                      name="especiesSelected"
                      isMulti={true}
                      options={this.state.especies}
                      placeholder="Todos"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      value={this.state.especiesSelected}
                      onChange={this.handleChange}
                      error={this.state.errors.especies}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="offset-lg-2 col-lg-4">
                    <Select
                      label="Fontes"
                      name="fontesSelected"
                      isMulti={true}
                      options={this.state.fontes}
                      placeholder="Todos"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      value={this.state.fontesSelected}
                      onChange={this.handleChange}
                      error={this.state.errors.fontes}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Select
                      label="Tipos de documento"
                      name="tiposDeDocumentoSelected"
                      isMulti={true}
                      options={this.state.tiposDeDocumento}
                      placeholder="Todos"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      value={this.state.tiposDeDocumentoSelected}
                      onChange={this.handleChange}
                      error={this.state.errors.tipos_documentos}
                    />
                  </div>
                </div>
                <div className="card shadow">
                  <div className="card-body bg-secondary">
                    <div className="row">
                      <div className="col-lg-12">
                        <Select
                          label="Selecione origens específicas"
                          name="origensSelected"
                          isMulti={true}
                          options={this.state.origens}
                          placeholder="Todos"
                          disabled={this.state.isWaiting ? "disabled" : undefined}
                          value={this.state.origensSelected}
                          onChange={this.handleChange}
                          error={this.state.errors.origens}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 text-center">Ou</div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <Input
                          label="Digite o nome da origem"
                          name="nome_origem"
                          placeholder="Nome da origem"
                          type="text"
                          disabled={this.state.isWaiting ? "disabled" : undefined}
                          defaultValue={this.state.nome_origem}
                          onChange={this.handleChange}
                          error={this.state.errors.nome_origem}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <Input
                      label="Histórico"
                      name="historico"
                      placeholder="Histórico"
                      type="text"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      defaultValue={this.state.historico}
                      onChange={this.handleChange}
                      error={this.state.errors.historico}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="offset-lg-2 col-lg-4">
                    <Select
                      label="Filtrar por:"
                      name="campo_data"
                      options={[
                        { value: "emissao", label: "Data de emissão" },
                        { value: "pagamento", label: "Data do pagamento" }
                      ]}
                      placeholder="Filtrar por:"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      value={this.state.campo_data}
                      onChange={this.handleChange}
                      error={this.state.errors.campo_data}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Select
                      label="Ordenar por"
                      name="order"
                      options={[
                        { value: "m.id", label: "Nº Do Movimento" },
                        { value: "m.documento", label: "Nº Do Documento" },
                        { value: "o.nome", label: "Nome da origem/Data de vencimento"}
                      ]}
                      placeholder="Todos"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      value={this.state.order}
                      onChange={this.handleChange}
                      error={this.state.errors.tipos_documentos}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <Input
                      label="Número do contrato"
                      name="numero_do_contrato"
                      type="text"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      placeholder="Número do contrato"
                      onChange={this.handleChange}
                      defaultValue={this.state.numero_do_contrato}
                      error={this.state.errors.numero_do_contrato}
                    />

                  </div>
                  <div className="col-lg-4">
                    <Input
                      label="Data inicial"
                      name="data_inicial"
                      placeholder="Data inicial"
                      type="date"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      defaultValue={this.state.data_inicial}
                      onChange={this.handleChange}
                      error={this.state.errors.data_inicial}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Input
                      label="Data final"
                      name="data_final"
                      type="date"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      placeholder="Data final"
                      onChange={this.handleChange}
                      defaultValue={this.state.data_final}
                      error={this.state.errors.data_final}
                    />
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-lg-2">
                    <Input
                      label="Imprimir em XLS"
                      name="xls"
                      placeholder="Imprimir em XLS"
                      type="checkbox"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      defaultValue={this.state.xls}
                      onChange={this.handleChange}
                      error={this.state.errors.xls}
                    />
                  </div>

                  <div className="col-lg-2">
                    <Input
                      label="Imprimir em XLS com histórico"
                      name="withHistorico"
                      placeholder="Imprimir em XLS com histórico"
                      type="checkbox"
                      disabled={!this.state.xls}
                      defaultValue={this.state.withHistorico}
                      onChange={this.handleChange}
                      error={this.state.errors.withHistorico}
                    />
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-lg-4">
                    <Select
                        label="Tipos de relatório:"
                        name="tipo_relatorio"
                        options={this.state.reportTypes}
                        placeholder="Tipos de relatório:"
                        disabled={this.state.isWaiting ? "disabled" : undefined}
                        value={this.state.tipo_relatorio}
                        onChange={this.handleChange}
                        error={this.state.errors.tipo_relatorio}
                    />
                  </div>

                  {(this.state.tipo_relatorio || {}).value != 4 ? "" : (
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6">
                          <Input
                              label="Data inicial (anos anteriores)"
                              name="data_inicial_anterior"
                              placeholder="Data inicial"
                              type="date"
                              disabled={this.state.isWaiting ? "disabled" : undefined}
                              defaultValue={this.state.data_inicial_anterior}
                              onChange={this.handleChange}
                              error={this.state.errors.data_inicial_anterior}
                          />
                        </div>
                        <div className="col-lg-6">
                          <Input
                              label="Data final (anos anteriores)"
                              name="data_final_anterior"
                              type="date"
                              disabled={this.state.isWaiting ? "disabled" : undefined}
                              placeholder="Data final"
                              onChange={this.handleChange}
                              defaultValue={this.state.data_final_anterior}
                              error={this.state.errors.data_final_anterior}
                          />
                        </div>
                        <div className="col-lg-4 my-auto">
                            <Input
                              label="Somar dívidas de anos anteriores"
                              name="adicionar_valores_anteriores"
                              placeholder="Somar dívidas de anos anteriores"
                              type="checkbox"
                              defaultValue={this.state.adicionar_valores_anteriores}
                              onChange={this.handleChange}
                              error={this.state.errors.adicionar_valores_anteriores}
                            />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <AdBlockDetect>
                  <div className="row">
                    <div className="col-lg-12 text-center bg-warning text-white py-3 my-4">
                      <i
                        style={{ fontSize: 50 }}
                        className="fas fa-exclamation-triangle"
                      />
                      <br />
                      <br /> Para que o relatório seja emitido, é necessário que
                      você desabilite seu AdBlocker
                  </div>
                  </div>
                </AdBlockDetect>
                {this.state.isWaiting && (
                  <div>
                    <div className="row">
                      <div className="col-lg-12 text-center">
                        <div className="lds-ring">
                          <div />
                          <div />
                          <div />
                          <div />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 text-center">
                        <h3 className="text-center">
                          Aguarde por favor, essa tarefa pode levar alguns
                          minutos!
                      </h3>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-lg-2 offset-lg-5">
                    <button
                      type="submit"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      className="btn btn-primary btn-block btn-lg"
                    >
                      <span className="btn--inner-icon">
                        <i className="ni ni-send" />
                      </span>
                      <span className="btn--inner-text"> Enviar</span>
                    </button>
                  </div>
                </div>
              </form>
            )}
        </div>
      </div>
    )
  }
}

export default withRouter(PagamentoPorPeriodoForm)
