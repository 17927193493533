import React, { Component } from "react"

class NoLabelInput extends Component {
  render() {
    return (
      <div className="form-group">
        <div className="input-group input-group-alternative mb-3">
          <div className="input-group-prepend">
            <span className={`input-group-text ${this.props.disabled}`}>
              <i className={this.props.icon || ""} />
            </span>
          </div>
          <input
            className={`form-control ${this.props.className || ""} ${
              this.props.error ? "has-danger" : ""
            }`}
            placeholder={this.props.placeholder}
            type={this.props.type || "text"}
            onChange={this.props.onChange}
            value={this.props.value}
            name={this.props.name}
            disabled={this.props.disabled}
            defaultValue={this.props.defaultValue}
          />
        </div>
        {this.props.error && (
          <span className="text-danger error-span">{this.props.error}</span>
        )}
      </div>
    )
  }
}

export default NoLabelInput
