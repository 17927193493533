///MOVIMENTO GERAL DE CAIXA FORM

import React, { Component } from "react"
import { Input } from "../../components"
import { withRouter } from "react-router-dom"
import { mapErrors, showNotification } from "../../helpers/Constants"
import { movimentoDeCaixaGeral } from "../../helpers/reportsApi"
import AdBlockDetect from "react-ad-block-detect"

class MovimentoGeralDeCaixaForm extends Component {
  constructor() {
    super()
    var date = new Date()
    var currentDate = [
      date.getFullYear(),
      (date.getMonth() + 1 + "").padStart(2, "0"),
      (date.getDate() + "").padStart(2, "0")
    ].join("-")
    var yesterday = [
      date.getFullYear(),
      (date.getMonth() + 1 + "").padStart(2, "0"),
      (date.getDate() - 1 + "").padStart(2, "0")
    ].join("-")
    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.toggleWaiting = this.toggleWaiting.bind(this)
    this.state = {
      errors: {},
      data_final: currentDate,
      data_inicial: yesterday,
      isWaiting: false
    }
  }
  toggleWaiting(callBack) {
    this.setState(
      {
        isWaiting: !this.state.isWaiting
      },
      () => {
        if (callBack) {
          callBack()
        }
      }
    )
  }
  handleChange(e) {
    let name = e.target.name
    let value = e.target.value
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: value,
      errors: errors
    })
  }
  onSubmit(e) {
    e.preventDefault()
    let params = {
      showTransferencias: this.state.showTransferencias,
      data_inicial: this.state.data_inicial,
      data_final: this.state.data_final
    }
    this.toggleWaiting()
    movimentoDeCaixaGeral(params, (error, data) => {
      this.toggleWaiting()
      if (error) {
        var message = ""
        switch (error.response.status) {
          case 400:
            message = "Não há dados a serem exibidos!"
            break
          case 422:
            message = "Ocorreram erros, verifiqueo formulário e tente novamente"
            break
          default:
            message = "Ocorreram erros, verifique sua conexão com a internet"
            break
        }
        showNotification(
          "top",
          "center",
          message,
          error.response.status === 400 ? "warning" : "danger"
        )
        if (error.response.status === 422) {
          const reader = new FileReader()
          reader.addEventListener("loadend", e => {
            const text = e.srcElement.result
            let json = JSON.parse(text)
            this.setState({
              errors: mapErrors({ response: { data: json } })
            })
          })
          reader.readAsText(error.response.data)
        }
      } else {
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    })
  }
  render() {
    return (
      <div className="card shadow">
        <div className="card-header border-0 bg-secondary">
          <div className="row text-center">
            <div className="col-lg-12">
              <h1>Relatório de movimento de caixa geral</h1>
            </div>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={this.onSubmit}>
            <div className="row">
              <div className="offset-lg-2 col-lg-4">
                <Input
                  label="Data inicial"
                  name="data_inicial"
                  placeholder="Data inicial"
                  type="date"
                  disabled={this.state.isWaiting ? "disabled" : undefined}
                  defaultValue={this.state.data_inicial}
                  onChange={this.handleChange}
                  error={this.state.errors.data_inicial}
                />
              </div>
              <div className="col-lg-4">
                <Input
                  label="Data final"
                  name="data_final"
                  type="date"
                  disabled={this.state.isWaiting ? "disabled" : undefined}
                  placeholder="Data final"
                  onChange={this.handleChange}
                  defaultValue={this.state.data_final}
                  error={this.state.errors.data_final}
                />
              </div>
            </div>
            <div className="row my-4">
              <div className="offset-lg-5 col-lg-4">
                <Input
                  label="Mostrar transferências?"
                  name="showTransferencias"
                  placeholder="Mostrar transferências?"
                  type="checkbox"
                  disabled={this.state.isWaiting ? "disabled" : undefined}
                  defaultValue={this.state.showTransferencias}
                  onChange={this.handleChange}
                  error={this.state.errors.showTransferencias}
                />
              </div>
            </div>
            <AdBlockDetect>
              <div className="row">
                <div className="col-lg-12 text-center bg-warning text-white py-3 my-4">
                  <i
                    style={{ fontSize: 50 }}
                    className="fas fa-exclamation-triangle"
                  />
                  <br />
                  <br /> Para que o relatório seja emitido, é necessário que
                  você desabilite seu AdBlocker
                </div>
              </div>
            </AdBlockDetect>
            {this.state.isWaiting && (
              <div>
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <div className="lds-ring">
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <h3 className="text-center">
                      Aguarde por favor, essa tarefa pode levar alguns minutos!
                    </h3>
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-lg-2 offset-lg-5">
                <button
                  disabled={this.state.isWaiting ? "disabled" : undefined}
                  type="submit"
                  className="btn btn-primary btn-block btn-lg"
                >
                  <span className="btn--inner-icon">
                    <i className="ni ni-send" />
                  </span>
                  <span className="btn--inner-text"> Enviar</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default withRouter(MovimentoGeralDeCaixaForm)
