import axios from "axios"
import { API, CLIENT_ID, CLIENT_SECRET, defaultHeaders } from "./Constants"

export function login(params, callback) {
  let address = API.split("api/").join("oauth/token")
  params.grant_type = "password"
  params.client_id = CLIENT_ID
  params.client_secret = CLIENT_SECRET
  axios
    .post(address, params)
    .then(response => {
      localStorage.token = response.data.access_token
      me(callback)
    })
    .catch(error => {
      callback(error)
    })
}

export function me(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = API + "me"
  axios
    .get(url, config)
    .then(response => {
      localStorage.me = JSON.stringify(response.data)
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
