import React, { Component } from "react"
import { Input } from "./"
export default class Search extends Component {
  render() {
    return (
      <div className="card shadow">
        <div className="card-header border-0 bg-secondary">
          <div className="row">
            <div className="col-lg-12">
              <h4>Colunas para pesquisar</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2">
              <Input
                type="checkbox"
                value={this.props.noneSelected}
                label="Todos"
                onChange={this.props.filterChanged}
                name="todos"
              />
            </div>
            {this.props.searchColumns.map((item, key) => {
              return (
                <div key={key} className="col-lg-2">
                  <Input
                    type="checkbox"
                    value={item.checked}
                    label={item.label}
                    onChange={this.props.filterChanged}
                    name={item.value}
                  />
                </div>
              )
            })}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-12 navbar-search">
              <div className="input-group input-group-alternative">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-search" />
                  </span>
                </div>
                <input
                  onChange={this.props.filterTextChanged}
                  className="form-control"
                  placeholder="Valor para pesquisar"
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
