import React, { Component } from "react"
import { Input, InputCurrency, Select, TextArea } from "../../components"
import { withRouter } from "react-router-dom"
import { post, edit, put, create } from "../../helpers/transferenciasApi"
import { mapErrors, showNotification } from "../../helpers/Constants"

class TransferenciaForm extends Component {
  constructor() {
    super()
    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.toggleWaiting = this.toggleWaiting.bind(this)
    this.state = { errors: {}, isLoading: true, fontes: [] }
  }

  componentWillMount() {
    this.getInitialData()
  }
  getInitialData() {
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        let fontes = data.fontes.map(fontes => {
          return { value: fontes.id, label: fontes.nome }
        })
        let newState = {
          fontes: fontes
        }
        if (this.props.match.params.id) {
          data.valor = parseFloat(data.valor)
          newState = {
            ...newState,
            ...data.transferencia
          }
        }
        this.setState(newState, () => {
          this.toggleLoading(() => {
            if (this.props.match.params.id) {
              if (data.transferencia.origem && data.transferencia.destino) {
                this.setState({
                  origem_id: {
                    value: data.transferencia.origem_id,
                    label: data.transferencia.origem.nome
                  },
                  destino_id: {
                    value: data.transferencia.destino_id,
                    label: data.transferencia.destino.nome
                  }
                })
              }
            }
          })
        })
      }
    }
    if (this.props.match.params.id) {
      edit(this.props.match.params.id, response)
    } else {
      create(response)
    }
  }
  toggleLoading(callback) {
    this.setState(
      {
        isLoading: !this.state.isLoading
      },
      () => {
        if (callback) {
          callback()
        }
      }
    )
  }
  handleChange(e) {
    let name = e.target.name
    let value = e.target.value
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: value,
      errors: errors
    })
  }
  toggleWaiting() {
    this.setState({
      isWaiting: !this.state.isWaiting
    })
  }
  onSubmit(e) {
    e.preventDefault()
    let params = {
      data: this.state.data,
      historico: this.state.historico,
      valor: this.state.valor,
      origem_id: this.state.origem_id ? this.state.origem_id.value : undefined,
      destino_id: this.state.destino_id
        ? this.state.destino_id.value
        : undefined,
      banco_id: this.state.banco_id ? this.state.banco_id.value : undefined
    }
    let response = error => {
      this.toggleWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        showNotification(
          "top",
          "center",
          `Transferência ${
            this.props.match.params.id ? "editada" : "incluída"
          } com sucesso`,
          "success"
        )
        this.props.history.push("/transferencias")
      }
    }
    this.toggleWaiting()
    this.props.match.params.id
      ? put(this.props.match.params.id, params, response)
      : post(params, response)
  }
  render() {
    return (
      <div className="card shadow">
        <div className="card-header border-0 bg-secondary">
          <div className="row text-center">
            <div className="col-lg-12">
              <h1>
                {this.props.match.params.id
                  ? "Editar transferência"
                  : "Adicionar nova transferência"}
              </h1>
            </div>
          </div>
        </div>
        <div className="card-body">
          {this.state.isLoading ? (
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="lds-ring">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
            </div>
          ) : (
            <form onSubmit={this.onSubmit}>
              <div className="row">
                <div className="col-lg-5">
                  <Select
                    label="Origem"
                    name="origem_id"
                    placeholder="Origem"
                    options={this.state.fontes}
                    value={this.state.origem_id}
                    onChange={this.handleChange}
                    disabled={this.state.isWaiting ? "disabled" : undefined}
                    error={this.state.errors.origem_id}
                  />
                </div>
                <div className="col-lg-5">
                  <Select
                    label="Destino"
                    name="destino_id"
                    placeholder="Destino"
                    disabled={this.state.isWaiting ? "disabled" : undefined}
                    options={this.state.fontes}
                    value={this.state.destino_id}
                    onChange={this.handleChange}
                    error={this.state.errors.destino_id}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-5">
                  <InputCurrency
                    label="Valor"
                    disabled={this.state.isWaiting ? "disabled" : undefined}
                    defaultValue={this.state.valor || 0}
                    name="valor"
                    error={this.state.errors.valor}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-lg-5">
                  <Input
                    label="Data da transferência"
                    name="data"
                    type="date"
                    disabled={this.state.isWaiting ? "disabled" : undefined}
                    placeholder="Data da transferência"
                    onChange={this.handleChange}
                    defaultValue={this.state.data}
                    error={this.state.errors.data}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <TextArea
                    label="Histórico"
                    name="historico"
                    disabled={this.state.isWaiting ? "disabled" : undefined}
                    defaultValue={this.state.historico || ""}
                    placeholder="Histórico"
                    rows="4"
                    onChange={this.handleChange}
                    error={this.state.errors.historico}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 offset-lg-5">
                  <button
                    type="submit"
                    disabled={this.state.isWaiting ? "disabled" : undefined}
                    className="btn btn-primary btn-block btn-lg"
                  >
                    <span className="btn--inner-icon">
                      <i className="ni ni-send" />
                    </span>
                    <span className="btn--inner-text"> Enviar</span>
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(TransferenciaForm)
