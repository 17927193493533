import React, { Component } from "react"

class NoLabelInput extends Component {
  render() {
    if (this.props.type === "radio") {
      return (
        <div className="custom-control custom-radio mb-3">
          <input
            id={this.props.value}
            disabled={this.props.disabled}
            name={this.props.name}
            checked={this.props.checked}
            className="custom-control-input"
            type="radio"
            value={this.props.value}
            onChange={this.props.onChange}
          />
          <label className="custom-control-label" htmlFor={this.props.value}>
            {this.props.label}
          </label>
        </div>
      )
    }
    if (this.props.type === "checkbox") {
      return (
        <div
          className={`custom-control custom-control-alternative custom-checkbox mb-3 ${this
            .props.className || ""}`}
        >
          <input
            className={"custom-control-input "}
            onChange={e =>
              this.props.onChange({
                target: { name: this.props.name, value: e.target.checked }
              })
            }
            checked={this.props.value}
            id={this.props.name}
            disabled={this.props.disabled}
            name={this.props.name}
            type="checkbox"
          />
          <label className="custom-control-label" htmlFor={this.props.name}>
            {this.props.label}
          </label>
          <br />
          {this.props.error && (
            <span className="text-danger error-span">{this.props.error}</span>
          )}
        </div>
      )
    }
    return (
      <div className="form-group">
        <label className="form-control-label" htmlFor={this.props.name}>
          {this.props.label}
        </label>
        <input
          className={`form-control form-control-alternative ${this.props
            .className || ""} ${this.props.error ? "has-danger" : ""}`}
          id={this.props.name}
          placeholder={this.props.placeholder}
          type={this.props.type || "text"}
          onChange={this.props.onChange}
          value={this.props.value}
          name={this.props.name}
          defaultValue={this.props.defaultValue}
          disabled={this.props.disabled}
        />
        {this.props.error && (
          <span className="text-danger error-span">{this.props.error}</span>
        )}
      </div>
    )
  }
}

export default NoLabelInput
