import React, { Component } from "react"

class TextArea extends Component {
  render() {
    return (
      <div className="form-group">
        <label className="form-control-label" htmlFor={this.props.name}>
          {this.props.label}
        </label>
        <textarea
          placeholder={this.props.placeholder}
          rows={this.props.rows}
          onChange={this.props.onChange}
          value={this.props.value}
          name={this.props.name}
          disabled={this.props.disabled}
          className={`form-control form-control-alternative ${this.props
            .className || ""} ${this.props.error ? "has-danger" : ""}`}
          id={this.props.name}
          defaultValue={this.props.defaultValue}
        />
        {this.props.error && (
          <span className="text-danger error-span">{this.props.error}</span>
        )}
      </div>
    )
  }
}

export default TextArea
