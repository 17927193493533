import React, { Component } from "react"
import { Input, InputCurrency, Select } from "../../components"
import { withRouter } from "react-router-dom"
import { post, edit, put, create } from "../../helpers/fontesApi"
import { mapErrors, showNotification } from "../../helpers/Constants"

class OrigemForm extends Component {
  constructor() {
    super()
    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.toggleWaiting = this.toggleWaiting.bind(this)
    this.state = { errors: {}, isLoading: true, bancos: [] }
  }

  componentWillMount() {
    this.getInitialData()
  }
  getInitialData() {
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        let bancos = data.bancos.map(banco => {
          return { value: banco.id, label: banco.nome }
        })
        let newState = {
          bancos: bancos
        }
        if (this.props.match.params.id) {
          data.saldo_inicial = parseFloat(data.saldo_inicial)
          newState = {
            ...newState,
            ...data.fonte
          }
        }
        this.setState(newState, () => {
          this.toggleLoading(() => {
            if (this.props.match.params.id) {
              if (data.fonte.banco) {
                this.setState({
                  banco_id: {
                    value: data.fonte.banco_id,
                    label: data.fonte.banco.nome
                  }
                })
              }
            }
          })
        })
      }
    }
    if (this.props.match.params.id) {
      edit(this.props.match.params.id, response)
    } else {
      create(response)
    }
  }
  toggleLoading(callback) {
    this.setState(
      {
        isLoading: !this.state.isLoading
      },
      () => {
        if (callback) {
          callback()
        }
      }
    )
  }
  handleChange(e) {
    let name = e.target.name
    let value = e.target.value
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: value,
      errors: errors
    })
  }
  toggleWaiting() {
    this.setState({
      isWaiting: !this.state.isWaiting
    })
  }
  onSubmit(e) {
    e.preventDefault()
    let params = {
      agencia: this.state.agencia,
      conta: this.state.conta,
      saldo_inicial: this.state.saldo_inicial,
      nome: this.state.nome,
      valor_mensal: this.state.valor_mensal,
      banco_id: this.state.banco_id ? this.state.banco_id.value : undefined
    }
    let response = error => {
      this.toggleWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        showNotification(
          "top",
          "center",
          `Fonte ${
            this.props.match.params.id ? "editada" : "incluída"
          } com sucesso`,
          "success"
        )
        this.props.history.push("/fontes")
      }
    }
    this.toggleWaiting()
    this.props.match.params.id
      ? put(this.props.match.params.id, params, response)
      : post(params, response)
  }
  render() {
    return (
      <div className="card shadow">
        <div className="card-header border-0 bg-secondary">
          <div className="row text-center">
            <div className="col-lg-12">
              <h1>
                {this.props.match.params.id
                  ? "Editar fonte"
                  : "Adicionar nova fonte"}
              </h1>
            </div>
          </div>
        </div>
        <div className="card-body">
          {this.state.isLoading ? (
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="lds-ring">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
            </div>
          ) : (
            <form onSubmit={this.onSubmit}>
              <div className="row">
                <div className="col-lg-5">
                  <Input
                    label="Nome"
                    name="nome"
                    placeholder="Nome"
                    defaultValue={this.state.nome}
                    onChange={this.handleChange}
                    disabled={this.state.isWaiting ? "disabled" : undefined}
                    error={this.state.errors.nome}
                  />
                </div>
                <div className="col-lg-5">
                  <Select
                    label="Banco"
                    name="banco_id"
                    placeholder="Banco"
                    onChange={this.handleChange}
                    options={this.state.bancos}
                    value={this.state.banco_id}
                    disabled={this.state.isWaiting ? "disabled" : undefined}
                    error={this.state.errors.banco_id}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <Input
                    label="Agência"
                    name="agencia"
                    type="text"
                    defaultValue={this.state.agencia}
                    placeholder="Agência"
                    disabled={this.state.isWaiting ? "disabled" : undefined}
                    onChange={this.handleChange}
                    error={this.state.errors.agencia}
                  />
                </div>
                <div className="col-lg-4">
                  <Input
                    label="Conta"
                    name="conta"
                    type="text"
                    placeholder="Conta"
                    disabled={this.state.isWaiting ? "disabled" : undefined}
                    defaultValue={this.state.conta}
                    onChange={this.handleChange}
                    error={this.state.errors.conta}
                  />
                </div>
                <div className="col-lg-4">
                  <InputCurrency
                    label="Saldo inicial"
                    defaultValue={this.state.saldo_inicial || 0}
                    name="saldo_inicial"
                    disabled={this.state.isWaiting ? "disabled" : undefined}
                    error={this.state.errors.saldo_inicial}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 offset-lg-5">
                  <button
                    type="submit"
                    disabled={this.state.isWaiting ? "disabled" : undefined}
                    className="btn btn-primary btn-block btn-lg"
                  >
                    <span className="btn--inner-icon">
                      <i className="ni ni-send" />
                    </span>
                    <span className="btn--inner-text"> Enviar</span>
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(OrigemForm)
