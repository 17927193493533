import React, { Component } from "react"
import { Input, InputCurrency, Select, TextArea } from "../../components"
import { withRouter } from "react-router-dom"
import { post, edit, put, create } from "../../helpers/movimentosApi"
import { mapErrors, showNotification } from "../../helpers/Constants"
import ReciboModal from "../ReciboModal"

class MovimentoForm extends Component {
  constructor() {
    super()
    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.toggleWaiting = this.toggleWaiting.bind(this)
    this.state = {
      errors: {},
      isLoading: true,
      isWaiting: false,
      fontes: [],
      origens: [],
      especies: [],
      tiposDeMovimento: [],
      tiposDeDocumento: []
    }
  }

  componentWillMount() {
    this.getInitialData()
  }
  getInitialData() {
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        let fontes = data.fontes.map(fontes => {
          return { value: fontes.id, label: fontes.nome }
        })
        let origens = data.origens.map(origem => {
          return { value: origem.id, label: origem.nome }
        })
        let especies = data.especies.map(especie => {
          return { value: especie.id, label: especie.nome }
        })
        let tiposDeDocumento = data.tipos_de_documento.map(tipo => {
          return { value: tipo.id, label: tipo.nome }
        })
        let tiposDeMovimento = data.tipos_de_movimento.map(tipo => {
          return { value: tipo.id, label: tipo.nome }
        })

        let newState = {
          fontes: fontes,
          especies: especies,
          origens: origens,
          tiposDeDocumento: tiposDeDocumento,
          tiposDeMovimento: tiposDeMovimento
        }
        if (this.props.match.params.id) {
          data.movimento.valor = parseFloat(data.movimento.valor)
          data.movimento.faturamento = parseFloat(data.movimento.faturamento)
          data.movimento.contrato_finalizado =
            data.movimento.contrato_finalizado === 1 ? true : false
          newState = {
            ...newState,
            ...data.movimento
          }
        }
        this.setState(newState, () => {
          this.toggleLoading(() => {
            if (this.props.match.params.id) {
              if (data.movimento.origem) {
                this.setState({
                  origem_id: {
                    value: data.movimento.origem_id,
                    label: data.movimento.origem.nome
                  }
                })
              }
              if (data.movimento.fonte) {
                this.setState({
                  fonte_id: {
                    value: data.movimento.fonte_id,
                    label: data.movimento.fonte.nome
                  }
                })
              }
              if (data.movimento.tipo) {
                this.setState({
                  tipo_id: {
                    value: data.movimento.tipo_id,
                    label: data.movimento.tipo.nome
                  }
                })
              }
              if (data.movimento.tipo_documento) {
                this.setState({
                  tipo_documento_id: {
                    value: data.movimento.tipo_documento_id,
                    label: data.movimento.tipo_documento.nome
                  },
                  especie_id: {
                    value: data.movimento.especie_id,
                    label: data.movimento.especie.nome
                  }
                })
              }
              if (data.movimento.especie) {
                this.setState({
                  especie_id: {
                    value: data.movimento.especie_id,
                    label: data.movimento.especie.nome
                  }
                })
              }
            }
          })
        })
      }
    }
    if (this.props.match.params.id) {
      edit(this.props.match.params.id, response)
    } else {
      create(response)
    }
  }
  toggleLoading(callback) {
    this.setState(
      {
        isLoading: !this.state.isLoading
      },
      () => {
        if (callback) {
          callback()
        }
      }
    )
  }
  toggleWaiting() {
    this.setState({
      isWaiting: !this.state.isWaiting
    })
  }
  handleChange(e) {
    let name = e.target.name
    let value = e.target.value
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: value,
      errors: errors
    })
  }
  onSubmit(e) {
    e.preventDefault()
    let params = {
      data_movimento: this.state.data_movimento,
      data_pagamento: this.state.data_pagamento,
      data_emissao: this.state.data_emissao,
      numero_do_contrato: this.state.numero_do_contrato,
      documento: this.state.documento,
      historico: this.state.historico,
      origem_id: this.state.origem_id ? this.state.origem_id.value : undefined,
      especie_id: this.state.especie_id
        ? this.state.especie_id.value
        : undefined,
      fonte_id: this.state.fonte_id ? this.state.fonte_id.value : undefined,
      tipo_documento_id: this.state.tipo_documento_id
        ? this.state.tipo_documento_id.value
        : undefined,
      tipo_id: this.state.tipo_id ? this.state.tipo_id.value : undefined,
      valor: this.state.valor,
      faturamento: this.state.faturamento,
      contrato_finalizado: this.state.contrato_finalizado || false
    }
    let response = (error, data) => {
      this.toggleWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        showNotification(
          "top",
          "center",
          `Movimento ${this.state.id ? "editada" : "incluída"} com sucesso`,
          "success"
        )
        if (this.state.id === undefined) {
          this.setState({
            id: data.id,
            origem: data.origem
          })
        }
      }
    }
    this.toggleWaiting()
    this.state.id
      ? put(this.state.id, params, response)
      : post(params, response)
  }
  render() {
    return (
      <div className="card shadow">
        <div className="card-header border-0 bg-secondary">
          <div className="row text-center">
            <div className="col-lg-12">
              <h1>
                {this.state.id
                  ? "Editar movimento"
                  : "Adicionar novo movimento"}
              </h1>
              {this.state.id && <strong>Movimento: {this.state.id}</strong>}
              {this.state.id && (
                <div className="col-lg-2 offset-lg-5">
                  <ReciboModal
                    label="Emitir recibo"
                    plain
                    md
                    margin="mt-3"
                    movimentoId={this.state.id}
                    valor={this.state.valor}
                    cliente={(this.state.origem || { nome: "" }).nome}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="card-body">
          {this.state.isLoading ? (
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="lds-ring">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
            </div>
          ) : (
              <form onSubmit={this.onSubmit}>
                <div className="row">
                  <div className="col-lg-5">
                    <Select
                      label="Origem"
                      name="origem_id"
                      placeholder="Origem"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      options={this.state.origens}
                      value={this.state.origem_id}
                      onChange={this.handleChange}
                      error={this.state.errors.origem_id}
                    />
                  </div>

                  <div className="col-lg-4">
                    <Select
                      label="Fonte"
                      name="fonte_id"
                      placeholder="Fonte"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      options={this.state.fontes}
                      value={this.state.fonte_id}
                      onChange={this.handleChange}
                      error={this.state.errors.fonte_id}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-5">
                    <Select
                      label="Espécie"
                      name="especie_id"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      placeholder="Espécie"
                      options={this.state.especies}
                      value={this.state.especie_id}
                      onChange={this.handleChange}
                      error={this.state.errors.especie_id}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Select
                      label="Tipo de movimento"
                      name="tipo_id"
                      placeholder="Tipo de movimento"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      options={this.state.tiposDeMovimento}
                      value={this.state.tipo_id}
                      onChange={this.handleChange}
                      error={this.state.errors.tipo_id}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3">
                    <Select
                      label="Tipo de documento"
                      name="tipo_documento_id"
                      placeholder="Tipo de documento"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      options={this.state.tiposDeDocumento}
                      value={this.state.tipo_documento_id}
                      onChange={this.handleChange}
                      error={this.state.errors.tipo_documento_id}
                    />
                  </div>
                  <div className="col-lg-3">
                    <Input
                      label="Documento"
                      name="documento"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      placeholder="Documento"
                      onChange={this.handleChange}
                      defaultValue={this.state.documento}
                      error={this.state.errors.documento}
                    />
                  </div>
                  <div className="col-lg-3">
                    <Input
                      label="Número do contrato"
                      name="numero_do_contrato"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      placeholder="Número do contrato"
                      onChange={this.handleChange}
                      defaultValue={this.state.numero_do_contrato}
                      error={this.state.errors.numero_do_contrato}
                    />
                  </div>
                  <div className="col-lg-3">
                    <Input
                      label="Data de emissão"
                      name="data_emissao"
                      type="date"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      placeholder="Data de emissão"
                      onChange={this.handleChange}
                      defaultValue={this.state.data_emissao}
                      error={this.state.errors.data_emissao}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-5">
                    <InputCurrency
                      label="Valor"
                      defaultValue={this.state.valor || 0}
                      name="valor"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      error={this.state.errors.valor}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-lg-4">
                    <InputCurrency
                      label="Valor pago"
                      defaultValue={this.state.faturamento || 0}
                      name="faturamento"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      error={this.state.errors.faturamento}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3">
                    <Input
                      label="Data do vencimento"
                      name="data_movimento"
                      type="date"
                      placeholder="Data do Vencimento"
                      onChange={this.handleChange}
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      defaultValue={this.state.data_movimento}
                      error={this.state.errors.data_movimento}
                    />
                  </div>
                  <div className="col-lg-3">
                    <Input
                      label="Data do pagamento"
                      name="data_pagamento"
                      type="date"
                      placeholder="Data do pagamento"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      onChange={this.handleChange}
                      defaultValue={this.state.data_pagamento}
                      error={this.state.errors.data_pagamento}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <TextArea
                      label="Histórico"
                      name="historico"
                      defaultValue={this.state.historico || ""}
                      placeholder="Histórico"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      rows="4"
                      onChange={this.handleChange}
                      error={this.state.errors.historico}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3">
                    <Input
                      label="Contrato finalizado?"
                      name="contrato_finalizado"
                      type="checkbox"
                      placeholder="Contrato finalizado?"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      onChange={this.handleChange}
                      value={this.state.contrato_finalizado}
                      error={this.state.errors.contrato_finalizado}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2 offset-lg-5">
                    <button
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      type="submit"
                      className="btn btn-primary btn-block btn-lg"
                    >
                      <span className="btn--inner-icon">
                        <i className="ni ni-send" />
                      </span>
                      <span className="btn--inner-text"> Enviar</span>
                    </button>
                  </div>
                </div>
              </form>
            )}
        </div>
      </div>
    )
  }
}

export default withRouter(MovimentoForm)
