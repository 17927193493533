import React, { Component } from "react"
import { Input, Select } from "../../components"
import { withRouter } from "react-router-dom"
import { create } from "../../helpers/movimentosApi"
import { mapErrors, showNotification } from "../../helpers/Constants"
import { movimentoAPagar } from "../../helpers/reportsApi"
import AdBlockDetect from "react-ad-block-detect"

class MovimentoApagar extends Component {
    constructor() {
        super()
        var date = new Date()
        var currentDate = [
            date.getFullYear(),
            (date.getMonth() + 1 + "").padStart(2, "0"),
            (date.getDate() + "").padStart(2, "0")
        ].join("-")
        var yesterday = [
            date.getFullYear(),
            (date.getMonth() + 1 + "").padStart(2, "0"),
            (date.getDate() - 1 + "").padStart(2, "0")
        ].join("-")
        this.handleChange = this.handleChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.toggleLoading = this.toggleLoading.bind(this)
        this.toggleWaiting = this.toggleWaiting.bind(this)
        this.state = {
            errors: {},
            isLoading: true,
            isWaiting: false,
            fontesSelected: [],
            relatorio_resumido:false,
            origensSelected: [],
            data_inicial: yesterday,
            data_final: currentDate,
            tiposSelected: [],
            especiesSelected: []
        }
    }

    componentWillMount() {
        this.getInitialData()
    }
    getInitialData() {
        create((error, data) => {
            this.toggleLoading()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
            } else {
                let fontes = data.fontes.map(fontes => {
                    return { value: fontes.id, label: fontes.nome }
                })
                let origens = data.origens.map(origem => {
                    return { value: origem.id, label: origem.nome }
                })
                let especies = data.especies.map(especie => {
                    return { value: especie.id, label: especie.nome }
                })
                let tiposDeMovimento = data.tipos_de_movimento.map(tipo => {
                    return { value: tipo.id, label: tipo.nome }
                })
                this.setState({
                    fontes: fontes,
                    especies: especies,
                    origens: origens,
                    tipos: tiposDeMovimento
                })
            }
        })
    }
    toggleLoading(callBack) {
        this.setState(
            {
                isLoading: !this.state.isLoading
            },
            () => {
                if (callBack) {
                    callBack()
                }
            }
        )
    }
    toggleWaiting(callBack) {
        this.setState(
            {
                isWaiting: !this.state.isWaiting
            },
            () => {
                if (callBack) {
                    callBack()
                }
            }
        )
    }

    handleChange(e) {
        let name = e.target.name
        let value = e.target.value
        let errors = this.state.errors
        errors[name] = undefined
        this.setState({
            [name]: value,
            errors: errors
        })


    }
    onSubmit(e) {
        e.preventDefault()
        let params = {
            relatorio_resumido: this.state.relatorio_resumido,
            tipos: this.state.tiposSelected.map(item => {
                return item.value
            }),
            origens: this.state.origensSelected.map(item => {
                return item.value
            }),
            fontes: this.state.fontesSelected.map(item => {
                return item.value
            }),
            especies: this.state.especiesSelected.map(item => {
                return item.value
            }),
            group_origem: this.state.group_origem,
            campo_data: this.state.campo_data
                ? this.state.campo_data.value
                : undefined,
            data_inicial: this.state.data_inicial,
            data_final: this.state.data_final
        }
        this.toggleWaiting()
        movimentoAPagar(params, (error, data) => {
            this.toggleWaiting()
            if (error) {
                var message = ""
                switch (error.response.status) {
                    case 400:
                        message = "Não há dados a serem exibidos!"
                        break
                    case 422:
                        message = "Ocorreram erros, verifiqueo formulário e tente novamente"
                        break
                    default:
                        message = "Ocorreram erros, verifique sua conexão com a internet"
                        break
                }
                showNotification(
                    "top",
                    "center",
                    message,
                    error.response.status === 400 ? "warning" : "danger"
                )
                if (error.response.status === 422) {
                    const reader = new FileReader()
                    reader.addEventListener("loadend", e => {
                        const text = e.srcElement.result
                        let json = JSON.parse(text)
                        this.setState({
                            errors: mapErrors({ response: { data: json } })
                        })
                    })
                    reader.readAsText(error.response.data)
                }
            } else {
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        })
    }
    render() {
        return (
            <div className="card shadow">
                <div className="card-header border-0 bg-secondary">
                    <div className="row text-center">
                        <div className="col-lg-12">
                            <h1>Relatório de movimentos a pagar</h1>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {this.state.isLoading ? (
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <div className="lds-ring">
                                    <div />
                                    <div />
                                    <div />
                                    <div />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <form onSubmit={this.onSubmit}>
                            <div className="row">
                                <div className="offset-lg-2 col-lg-4">
                                    <Select
                                        label="Fontes"
                                        name="fontesSelected"
                                        isMulti={true}
                                        options={this.state.fontes}
                                        placeholder="Todas"
                                        value={this.state.fontesSelected}
                                        onChange={this.handleChange}
                                        disabled={this.state.isWaiting ? "disabled" : undefined}
                                        error={this.state.errors.fontes}
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <Select
                                        label="Tipos"
                                        name="tiposSelected"
                                        isMulti={true}
                                        options={this.state.tipos}
                                        placeholder="Todos"
                                        value={this.state.tiposSelected}
                                        disabled={this.state.isWaiting ? "disabled" : undefined}
                                        onChange={this.handleChange}
                                        error={this.state.errors.tipos}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="offset-lg-2 col-lg-4">
                                    <Select
                                        label="Origens"
                                        name="origensSelected"
                                        isMulti={true}
                                        options={this.state.origens}
                                        placeholder="Todos"
                                        disabled={this.state.isWaiting ? "disabled" : undefined}
                                        value={this.state.origensSelected}
                                        onChange={this.handleChange}
                                        error={this.state.errors.origens}
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <Select
                                        label="Espécies"
                                        name="especiesSelected"
                                        isMulti={true}
                                        options={this.state.especies}
                                        placeholder="Todos"
                                        disabled={this.state.isWaiting ? "disabled" : undefined}
                                        value={this.state.especiesSelected}
                                        onChange={this.handleChange}
                                        error={this.state.errors.especies}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="offset-lg-2 col-lg-4">
                                    <Input
                                        label="Data inicial"
                                        name="data_inicial"
                                        placeholder="Data inicial"
                                        type="date"
                                        disabled={this.state.isWaiting ? "disabled" : undefined}
                                        defaultValue={this.state.data_inicial }
                                        onChange={this.handleChange}
                                        error={this.state.errors.data_inicial}
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <Input
                                        label="Data final"
                                        name="data_final"
                                        type="date"
                                        disabled={this.state.isWaiting ? "disabled" : undefined}
                                        placeholder="Data final"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.data_final}
                                        error={this.state.errors.data_final}
                                    />
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="offset-lg-2 col-lg-4">
                                    <Input
                                        id={"relatorio_resumido"}
                                        checked={true}
                                        onChange={this.handleChange}
                                        label="Relatório Resumido"
                                        name="relatorio_resumido"
                                        type="checkbox"/>
                                </div>


                            </div>
                            <AdBlockDetect>
                                <div className="row">
                                    <div className="col-lg-12 text-center bg-warning text-white py-3 my-4">
                                        <i
                                            style={{ fontSize: 50 }}
                                            className="fas fa-exclamation-triangle"
                                        />
                                        <br />
                                        <br /> Para que o relatório seja emitido, é necessário que
                                        você desabilite seu AdBlocker
                                    </div>
                                </div>
                            </AdBlockDetect>
                            {this.state.isWaiting && (
                                <div>
                                    <div className="row">
                                        <div className="col-lg-12 text-center">
                                            <div className="lds-ring">
                                                <div />
                                                <div />
                                                <div />
                                                <div />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 text-center">
                                            <h3 className="text-center">
                                                Aguarde por favor, essa tarefa pode levar alguns
                                                minutos!
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="row">
                                <div className="col-lg-2 offset-lg-5">
                                    <button
                                        type="submit"
                                        disabled={this.state.isWaiting ? "disabled" : undefined}
                                        className="btn btn-primary btn-block btn-lg"
                                    >
                    <span className="btn--inner-icon">
                      <i className="ni ni-send" />
                    </span>
                                        <span className="btn--inner-text"> Enviar</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        )
    }
}

export default withRouter(MovimentoApagar)
