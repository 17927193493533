import React, { Component } from "react"
import { Input } from "../../components"
import { withRouter } from "react-router-dom"
import { post, edit, put } from "../../helpers/tiposApi"
import { mapErrors, showNotification } from "../../helpers/Constants"

class TipoForm extends Component {
  constructor() {
    super()
    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.toggleWaiting = this.toggleWaiting.bind(this)
    this.state = { errors: {}, isLoading: true }
  }

  componentWillMount() {
    this.getInitialData()
  }
  getInitialData() {
    if (this.props.match.params.id) {
      edit(this.props.match.params.id, (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
        } else {
          this.setState(data, () => {
            this.toggleLoading()
          })
        }
      })
    } else {
      this.toggleLoading()
    }
  }
  toggleLoading() {
    this.setState({
      isLoading: !this.state.isLoading
    })
  }
  handleChange(e) {
    let name = e.target.name
    let value = e.target.value
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: value,
      errors: errors
    })
  }
  toggleWaiting() {
    this.setState({
      isWaiting: !this.state.isWaiting
    })
  }
  onSubmit(e) {
    e.preventDefault()
    let params = {
      nome: this.state.nome
    }
    let response = error => {
      this.toggleWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        showNotification(
          "top",
          "center",
          `Tipo de movimento ${
            this.props.match.params.id ? "editado" : "incluído"
          } com sucesso`,
          "success"
        )
        this.props.history.push("/tipos")
      }
    }
    this.toggleWaiting()
    this.props.match.params.id
      ? put(this.props.match.params.id, params, response)
      : post(params, response)
  }
  render() {
    return (
      <div className="card shadow">
        <div className="card-header border-0 bg-secondary">
          <div className="row text-center">
            <div className="col-lg-12">
              <h1>
                {this.props.match.params.id
                  ? "Editar tipo de movimento"
                  : "Adicionar novo tipo de movimento"}
              </h1>
            </div>
          </div>
        </div>
        <div className="card-body">
          {this.state.isLoading ? (
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="lds-ring">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
            </div>
          ) : (
            <form onSubmit={this.onSubmit}>
              <div className="row">
                <div className="col-lg-12">
                  <Input
                    label="Nome"
                    name="nome"
                    placeholder="Nome"
                    disabled={this.state.isWaiting ? "disabled" : undefined}
                    defaultValue={this.state.nome}
                    onChange={this.handleChange}
                    error={this.state.errors.nome}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 offset-lg-5">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block btn-lg"
                  >
                    <span className="btn--inner-icon">
                      <i className="ni ni-send" />
                    </span>
                    <span className="btn--inner-text"> Enviar</span>
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(TipoForm)
