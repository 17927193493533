import React, { Component } from "react"
import { Input } from "../../components"
import { withRouter } from "react-router-dom"
import { bloquear, editBloqueio as edit } from "../../helpers/movimentosApi"
import { showNotification } from "../../helpers/Constants"

class DataDeBloqueioForm extends Component {
  constructor() {
    super()
    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.toggleWaiting = this.toggleWaiting.bind(this)
    this.state = { errors: {}, isLoading: true, bancos: [] }
  }

  componentWillMount() {
    this.getInitialData()
  }
  getInitialData() {
    let response = (error, data) => {

      if (error) {
        this.toggleLoading()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.setState({
          data: data.data
        }, () => {
          this.toggleLoading()
        })
      }
    }
    edit(response)

  }
  toggleLoading(callback) {
    this.setState(
      {
        isLoading: !this.state.isLoading
      },
      () => {
        if (callback) {
          callback()
        }
      }
    )
  }
  handleChange(e) {
    let name = e.target.name
    let value = e.target.value
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: value,
      errors: errors
    })
  }
  toggleWaiting() {
    this.setState({
      isWaiting: !this.state.isWaiting
    })
  }
  onSubmit(e) {
    e.preventDefault()
    let response = error => {
      this.toggleWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        showNotification(
          "top",
          "center",
          "Data de bloqueio alterada com sucesso",
          "success"
        )
      }
    }
    this.toggleWaiting()
    bloquear(this.state.data, response)
  }
  render() {
    return (
      <div className="card shadow">
        <div className="card-header border-0 bg-secondary">
          <div className="row text-center">
            <div className="col-lg-12">
              <h1>
                Bloqueio de movimentos
              </h1>
            </div>
          </div>
        </div>
        <div className="card-body">
          {this.state.isLoading ? (
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="lds-ring">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
            </div>
          ) : (
              <form onSubmit={this.onSubmit}>
                <div className="row">
                  <div className="offset-lg-3 col-lg-5">
                    <Input
                      label="Data de bloqueio"
                      name="data"
                      type="date"
                      placeholder="data"
                      defaultValue={this.state.data}
                      onChange={this.handleChange}
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      error={this.state.errors.data}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-2 offset-lg-5">
                    <button
                      type="submit"
                      disabled={this.state.isWaiting ? "disabled" : undefined}
                      className="btn btn-primary btn-block btn-lg"
                    >
                      <span className="btn--inner-icon">
                        <i className="ni ni-send" />
                      </span>
                      <span className="btn--inner-text"> Alterar</span>
                    </button>
                  </div>
                </div>
              </form>
            )}
        </div>
      </div>
    )
  }
}

export default withRouter(DataDeBloqueioForm)
