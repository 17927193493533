import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { me } from "../helpers/authApi"
class Dropdown extends Component {
  constructor() {
    super()
    this.logout = this.logout.bind(this)
    this.state = {
      me: {}
    }
  }
  componentDidMount() {
    me((error, data) => {
      if (!error) {
        this.setState({
          me: data
        })
      }
    })
  }

  logout() {
    delete localStorage.token
    delete localStorage.me
    this.props.history.push("/login")
  }
  render() {
    return (
      <li className="nav-item dropdown">
        <h4
          style={{ cursor: "pointer" }}
          className="pr-0"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div className="media align-items-center">
            <span className="avatar bg-white avatar-md rounded-circle">
              <i className={this.props.icon + " text-info"} />
            </span>
          </div>
        </h4>
        <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-right">
          <div className="px-2 text-center">
            <small>Usuário</small>
            <h4>{this.state.me.username}</h4>
          </div>
          {(this.props.itens || []).map((item, key) => {
            if (item.onClick) {
              return (
                <button
                  type="button"
                  key={key}
                  className="dropdown-item"
                >
                  <i className={item.icon} />
                  <span>{item.label}</span>
                </button>
              )
            }
            return (
              <Link key={key} to={item.link}  className="dropdown-item">
                <i className={item.icon} />
                <span>{item.label}</span>
              </Link>
            )
          })}
          <button
            style={{ cursor: "pointer" }}
            onClick={this.logout}
            className="dropdown-item"
          >
            <i className="ni ni-user-run" />
            <span>Sair</span>
          </button>
        </div>
      </li>
    )
  }
}
export default withRouter(Dropdown)
