import React from "react"
import ReactDOM from "react-dom"
import Routes from "./Routes"
import * as serviceWorker from "./serviceWorker"
import "./css/argon.css"
import "./css/nucleo.css"
import "./css/index.css"
import "./css/animate.css"
import "./css/react-confirm-alert.css"
ReactDOM.render(<Routes />, document.getElementById("root"))
serviceWorker.unregister()
