import React, { Component } from "react"
import { InputCurrency, Input } from "../components"
import Modal from "../components/Modal"
import numeral from "../helpers/numeral"
import { recibo } from "../helpers/reportsApi"
import { showNotification, mapErrors } from "../helpers/Constants"
export default class ReciboModal extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.state = {
      errors: []
    }
  }
  handleChange(e) {
    let name = e.target.name
    let value = e.target.value
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: value,
      errors: errors
    })
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  onSubmit(e) {
    e.preventDefault()
    let params = {
      calcular_impostos: this.state.calcular_impostos,
      iss: this.state.calcular_impostos ? this.state.iss : 0,
      exibir_cabecalho: this.state.exibir_cabecalho
    }
    this.toggleLoading()
    recibo(this.props.movimentoId, params, (error, data) => {
      this.toggleLoading()
      if (error) {
        var message = ""
        switch (error.response.status) {
          case 400:
            message = "Não há dados a serem exibidos!"
            break
          case 422:
            message = "Ocorreram erros, verifiqueo formulário e tente novamente"
            break
          default:
            message = "Ocorreram erros, verifique sua conexão com a internet"
            break
        }
        showNotification(
          "top",
          "center",
          message,
          error.response.status === 400 ? "warning" : "danger"
        )
        if (error.response.status === 422) {
          const reader = new FileReader()
          reader.addEventListener("loadend", e => {
            const text = e.srcElement.result
            let json = JSON.parse(text)
            this.setState({
              errors: mapErrors({ response: { data: json } })
            })
          })
          reader.readAsText(error.response.data)
        }
      } else {
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    })
  }
  render() {
    return (
      <Modal
        margin={this.props.margin}
        plain={this.props.plain || false}
        md={this.props.md || false}
        title="Gerar recibo"
        triggerLabel={this.props.label}
        triggerTitle="Imprimir Recibo"
        centralized
        id={"recibo" + this.props.movimentoId}
        triggerIcon={this.props.triggerIcon || "ni ni-paper-diploma"}
      >
        <form onSubmit={this.onSubmit}>
          <h4>Cliente: {this.props.cliente}</h4>
          <h5>
            Valor do movimento:{" "}
            {numeral(parseFloat(this.props.valor))
              .format("0.0,00")
              .slice(0, -2)}
          </h5>
          <Input
            label="Calcular impostos?"
            name="calcular_impostos"
            type="checkbox"
            placeholder="Calcular impostos?"
            onChange={this.handleChange}
            disabled={this.state.isLoading}
            value={this.state.calcular_impostos}
            error={this.state.errors.calcular_impostos}
          />
          {this.state.calcular_impostos && (
            <InputCurrency
              label="Alíquota ISS"
              currencyStyle="decimal"
              defaultValue={this.state.iss || 0}
              name="iss"
              disabled={this.state.isLoading}
              error={this.state.errors.iss}
              onChange={this.handleChange}
            />
          )}
          {this.state.calcular_impostos && (
            <InputCurrency
              label="ISS"
              currencyStyle="decimal"
              disabled
              value={((this.state.iss || 0) * this.props.valor) / 100}
              name="iss"
              error={this.state.errors.iss}
              onChange={this.handleChange}
            />
          )}
          {this.state.calcular_impostos && (
            <InputCurrency
              label="IRRF"
              disabled
              currencyStyle="decimal"
              defaultValue={(1.5 / 100) * this.props.valor}
              name="irrf"
              error={this.state.errors.irrf}
              onChange={this.handleChange}
            />
          )}
          <Input
            label="Exibir cabeçalho?"
            name="exibir_cabecalho"
            type="checkbox"
            placeholder="Exibir cabeçalho?"
            onChange={this.handleChange}
            disabled={this.state.isLoading}
            value={this.state.exibir_cabecalho}
            error={this.state.errors.exibir_cabecalho}
          />
          {this.state.isLoading && (
            <div>
              <div className="row">
                <div className="col-lg-12 text-center">
                  <div className="lds-ring">
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h3 className="text-center">
                    Aguarde por favor, essa tarefa pode levar alguns segundos!
                  </h3>
                </div>
              </div>
            </div>
          )}
          <button
            disabled={this.state.isLoading}
            className="btn btn-default"
            type="submit"
          >
            Enviar
          </button>
        </form>
      </Modal>
    )
  }
}
