import React, { Component } from "react"
import { Link } from "react-router-dom"

export default class NotFound extends Component {
  render() {
    return (
      <div>
        <div className="bg-full bg-warning" />
        <div className="header bg-gradient-danger py-7 py-lg-8">
          <h1
            style={{ fontSize: 53, marginLeft: "20%" }}
            className="text-white"
          >
            ERRO 404!
          </h1>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              x="0"
              y="0"
              viewBox="0 0 2560 100"
              preserveAspectRatio="none"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polygon
                className="fill-warning"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        <div className="container mt-4">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 text-center">
              <h1 className="text-white">
                A página que você está procurando não existe
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="offset-lg-3 col-lg-6 col-md-8 text-center">
              <Link to="/movimentos" className="text-white">
                Voltar ao inicio
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
