import React, { Component } from "react"
import { withRouter, Link } from "react-router-dom"
import { get, destroy } from "../helpers/tiposApi"
import { showNotification } from "../helpers/Constants"
import { confirmAlert } from "react-confirm-alert"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
class TiposDeMovimento extends Component {
  constructor() {
    super()
    this.getData = this.getData.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.filter = this.filter.bind(this)
    this.state = {
      tipos: [],
      allTipos: [],
      isLoading: true
    }
  }
  componentDidMount() {
    window.$("[data-toggle=\"tooltip\"]").tooltip({
      trigger: "hover"
    })
  }
  componentWillUnmount() {
    window.$(".tooltip").remove()
  }

  componentDidUpdate() {
    window.$("[data-toggle=\"tooltip\"]").tooltip({
      trigger: "hover"
    })
  }
  delete(id, index) {
    confirmAlert({
      title: "Confirme a remoção",
      message: "Tem certeza que deseja deletar?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            destroy(id, error => {
              if (error) {
                let errorMessage = (
                  (error.response || { data: { message: undefined } }).data || {
                    message: undefined
                  }
                ).message
                showNotification(
                  "top",
                  "center",
                  errorMessage || "Erro desconhecido",
                  "danger"
                )
              } else {
                showNotification(
                  "top",
                  "center",
                  "Tipo deletado com sucesso",
                  "success"
                )
                let currentTipos = this.state.tipos
                currentTipos.splice(index, 1)
                this.setState({
                  tipos: currentTipos,
                  allTipos: currentTipos
                })
              }
            })
          }
        },
        {
          label: "Não"
        }
      ]
    })
  }
  toggleLoading() {
    this.setState({
      isLoading: !this.state.isLoading
    })
  }
  componentWillMount() {
    this.getData()
  }
  getData() {
    get((error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.toggleLoading()
        this.setState({
          tipos: data,
          allTipos: data
        })
      }
    })
  }
  filter(e) {
    let value = e.target.value.toLowerCase()
    this.setState({
      tipos: this.state.allTipos.filter(item => {
        return item.nome.toLowerCase().includes(value)
      })
    })
  }
  render() {
    return (
      <div className="card shadow">
        <div className="card-header border-0 bg-secondary">
          <div className="row text-center">
            <div className="col-lg-12">
              <h1>Tipos de movimento</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 offset-lg-4 text-center">
              <Link to="/tipos/create" className="btn btn-success btn-block">
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-add" />
                </span>
                <span className="btn-inner--text"> Adicionar</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="form-group col-lg-12 mb-3 navbar-search">
              <div
                style={{ borderColor: "#f6f9fc" }}
                className="input-group input-group-alternative"
              >
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-search" />
                  </span>
                </div>
                <input
                  onChange={this.filter}
                  className="form-control"
                  placeholder="Pesquise o tipo de movimento aqui"
                  type="text"
                />
              </div>
            </div>
          </div>
          {this.state.isLoading ? (
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="lds-ring">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
            </div>
          ) : (
            <div className="table-responsive">
              <table className="table align-items-center table-flush">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nome</th>
                    <th style={{ width: "15%" }} scope="col">
                      Ações
                    </th>
                  </tr>
                </thead>
                <ReactCSSTransitionGroup
                  component="tbody"
                  transitionEnterTimeout={500}
                  transitionLeaveTimeout={500}
                  transitionName="example"
                >
                  {this.state.tipos.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td className="normal-wrap">{item.id}</td>
                        <td className="normal-wrap">{item.nome}</td>
                        <td className="normal-wrap">
                          <Link
                            to={`tipos/edit/${item.id}`}
                            className="btn btn-outline-info"
                            data-toggle="tooltip"
                            title="Editar tipo de movimento"
                          >
                            <span className="btn-inner--icon">
                              <i className="ni ni-ruler-pencil" />
                            </span>
                          </Link>
                          <button
                            onClick={() => this.delete(item.id, key)}
                            type="button"
                            data-toggle="tooltip"
                            title="Deletar tipo de movimento"
                            className="btn btn-outline-danger"
                          >
                            <span className="btn-inner--icon">
                              <i className="ni ni-fat-remove" />
                            </span>
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </ReactCSSTransitionGroup>
              </table>
            </div>
          )}
        </div>
      </div>
    )
  }
}
export default withRouter(TiposDeMovimento)
