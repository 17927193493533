import React, { Component } from "react"
import { Link } from "react-router-dom"
import logo from "../img/alucom_logo_ajust.png"
import { Dropdown, CollapseMenu } from "."
class Sidebar extends Component {
  render() {
    return (
      <nav
        className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white"
        id="sidenav-main"
      >
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#sidenav-collapse-main"
            aria-controls="sidenav-main"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <Link className="navbar-brand pt-0" to="/movimentos">
            <img src={logo} className="navbar-brand-img" alt="logo" />
          </Link>
          <ul className="nav align-items-center d-md-none">
            <Dropdown icon="ni ni-single-02" />
          </ul>
          <div className="collapse navbar-collapse" id="sidenav-collapse-main">
            <div className="navbar-collapse-header d-md-none">
              <div className="row">
                <div className="col-3 offset-4 collapse-brand">
                  <Link to="/movimentos">
                    <img src={logo} alt="logo" />
                  </Link>
                </div>
                <div className="col-5 collapse-close">
                  <button
                    type="button"
                    className="navbar-toggler"
                    data-toggle="collapse"
                    data-target="#sidenav-collapse-main"
                    aria-controls="sidenav-main"
                    aria-expanded="false"
                    aria-label="Toggle sidenav"
                  >
                    <span />
                    <span />
                  </button>
                </div>
              </div>
            </div>
            <ul className="navbar-nav">
              {(this.props.menuItens || []).map((item, key) => {
                if (item.subItens) {
                  return <CollapseMenu key={key} item={item} />
                }
                return (
                  <li
                    key={key}
                    className={`nav-item rounded-nav-link ${
                      window.location.pathname.includes(item.link) &&
                      !window.location.pathname.includes("Report")
                        ? "active"
                        : ""
                    } `}
                  >
                    <Link className="nav-link " to={item.link}>
                      <i className={`${item.icon}`} />
                      {item.label}
                    </Link>
                  </li>
                )
              })}
            </ul>
            <hr className="my-3" />
            <h6 className="navbar-heading text-muted">
              AS Sistemas Consultoria Pública © 2019
            </h6>
          </div>
        </div>
      </nav>
    )
  }
}

export default Sidebar
