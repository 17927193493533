import axios from "axios"
import { API, defaultHeaders } from "./Constants"
export function header(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = API + "headerData"
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
