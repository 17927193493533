import axios from "axios"
import { API, defaultHeaders } from "./Constants"
export function get(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = API + "fontes"
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
export function create(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = API + "fontes/create"
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
export function edit(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = API + `fontes/${id}/edit`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
export function post(params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = API + "fontes"
  axios
    .post(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function put(id, params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = API + "fontes/" + id
  axios
    .put(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function destroy(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = API + "fontes/" + id
  axios
    .delete(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
